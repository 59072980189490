export const environment = {
  production: true,
  // emailService: 'http://lemon.local/api/emails.send',
  // getReviewList: 'http://lemon.local/api/reviews.list',
  // getReview: 'http://lemon.local/api/reviews.info',
  // getReviewExternal: 'http://lemon.local/api/reviews.external',
  // submitReview: 'http://lemon.local/api/reviews.submit',
  // signup: 'http://lemon.local/api/signup',
  // login: 'http://lemon.local/api/token',
  // upload: 'http://lemon.local/api/users.update',
  // userInfo: 'http://lemon.local/api/users.info',
  // userLogo: 'http://lemon.local/api/users.logo',
  // signups: 'http://lemon.local/api/admin/signups.list',
  // customers: 'http://lemon.local/api/admin/customers.list',
  // loginAsUser: 'http://lemon.local/api/admin/login',
  // plans: 'http://lemon.local/api/plans.list',
  // subscribe: 'http://lemon.local/api/subscribe.create',
  // noCharge: 'http://lemon.local/api/admin/nocharge.update',
  // reviewsLemonClick: 'http://lemon.local/api/reviews.lemon.click'
  emailService: 'https://trylemon.com/api/emails.send',
  getReviewList: 'https://trylemon.com/api/reviews.list',
  getReview: 'https://trylemon.com/api/reviews.info',
  getReviewExternal: 'https://trylemon.com/api/reviews.external',
  submitReview: 'https://trylemon.com/api/reviews.submit',
  signup: 'https://trylemon.com/api/signup',
  login: 'https://trylemon.com/api/token',
  upload: 'https://trylemon.com/api/users.update',
  userInfo: 'https://trylemon.com/api/users.info',
  userLogo: 'https://trylemon.com/api/users.logo',
  signups: 'https://trylemon.com/api/admin/signups.list',
  customers: 'https://trylemon.com/api/admin/customers.list',
  loginAsUser: 'https://trylemon.com/api/admin/login',
  plans: 'https://trylemon.com/api/plans.list',
  subscribe: 'https://trylemon.com/api/subscribe.create',
  noCharge: 'https://trylemon.com/api/admin/nocharge.update',
  reviewsLemonClick: 'https://trylemon.com/api/reviews.lemon.click'
};
